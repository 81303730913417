import { PropsWithChildren } from "react";
import { BackgroundColors } from "../../types/theme.js";
import { useClassList } from "../../hooks/useClassList.js";
import { checkArrow } from "../../icons/svg.js";

interface DateChipProps {
  label: string;
}

export const ChipDate: React.FC<DateChipProps> = ({ label }) => {
  if (!label) return null;

  return (
    <div className="chip">
      <div className="chip-date">
        <span className="b3">{label}</span>
      </div>
    </div>
  );
};

interface ChipInfoProps {
  label: string;
  variant?: keyof BackgroundColors;
}

export const ChipInfo: React.FC<ChipInfoProps> = ({
  label,
  variant = "primary",
}) => {
  if (!label) return null;

  return (
    <div className="chip">
      <div className="chip-info">
        <div className={`circle ${variant}`}>{checkArrow}</div>
        <span className="label b3">{label}</span>
      </div>
    </div>
  );
};

interface SelectChipProps {
  label: string;
  isSelected: boolean;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ChipSelect: React.FC<SelectChipProps> = ({
  label,
  isSelected,
  onSelect,
}) => {
  const selectClass = useClassList("chip-select", "b3", {
    class: "selected",
    active: isSelected,
  });

  if (!label) return null;

  return (
    <div className="chip">
      <label className={selectClass.classNames}>
        {label}
        {isSelected && checkArrow}
        <input type="checkbox" onChange={onSelect} />
      </label>
    </div>
  );
};

export const ChipList: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="chip-list">{children}</div>;
};
